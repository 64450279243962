.wrapper {
  position: relative;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.high-res-image {
  position: absolute;
  width: 100%;
  height: 100%;
}
