.navbarWrapper {
  z-index: 10;
  position: fixed;
  top: 0;
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.15) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}

.navbarWrapper.mobile {
  height: "auto";
}
.navbarWrapper.mobile.background {
  background: rgb(49, 49, 49);
  background: linear-gradient(
    180deg,
    rgba(49, 49, 49, 1) 85%,
    rgba(0, 0, 0, 0) 100%
  );
}
.navbarWrapper.mobile.noBackground {
  background: rgba(0, 0, 0, 0);
}

.navbarWrapper.mobile.open {
  background: rgba(0, 0, 0, 0);
}

.navbarWrapper.desktop {
  height: 120px;
  backdrop-filter: blur(1px);
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10%;
  margin-left: calc(10% - 100px);
  height: 100px;
  width: 100%;
}

.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100%;
  align-items: center;
}

.mobile-navbar.open {
  height: 100vh;
  background: rgba(0, 0, 0, 0);
}

.link {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}

.link:hover {
  color: lightgrey;
}

.logo {
  width: 120px;
  height: 120px;
  align-self: left;
  filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 0.4));
}

.menu-button {
  color: #fff;
}

.menu-button:hover,
menu-button.active {
  color: lightgrey;
}

.vertical-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 15%;
  height: 45%;
}

.vertical-link {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}

.vertical-link:hover {
  color: lightgrey;
}

.vertical-link.active {
  color: lightgrey;
}

.menu-button-wrapper {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 800;
}
.horizontal-line {
  width: 100%;
  border: 1px solid #fff;
}

.menu-button-wrapper--open {
  margin-top: 15%;
}

.backgroundBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  backdrop-filter: blur(8px);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.15) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}
