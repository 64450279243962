:root {
  --main-bg-color: #313131;
  --main-panel-color: #1f1f1f;
  --main-text-color: #fff;
}
html {
  min-height: 100%;
}
body {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-family: "Crimson Text", serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
}

div#root {
  min-height: 100vh;
}

.app {
  width: 100%;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
}

.custom-container {
  display: flex;
  padding-top: 250px;
}

@media only screen and (max-width: 1000px) {
  .custom-container {
    display: flex;
    padding-top: 175px;
    padding-bottom: 100px;
  }
}

.page-title {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  padding-bottom: 14px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

@media only screen and (min-width: 1001px) {
  p {
    font-size: 16px;
  }
}

input.input-text {
  appearance: none;
  background-color: #313131;
  color: #fff;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  font-size: 14px;
  text-align: center;
  width: 95%;
  padding: 8px;
}

textarea {
  appearance: none;
  background-color: #313131;
  color: #fff;
  border: 1px solid #eaeaea;
  font-size: 14px;
  width: 95%;
  min-height: 300px;
  padding: 8px;
}

select {
  appearance: none;
  background-color: #313131;
  color: #fff;
  border: 1px solid #eaeaea;
  font-size: 14px;
  text-align: center;
  width: 95%;
  padding: 8px;
  cursor: pointer;
}

select:hover {
  background-color: #464646;
}

input.submit {
  appearance: none;
  background-color: #ffffff;

  border: 0.5px solid #eaeaea;
  text-align: center;
  font-family: "Crimson Text", serif;
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: calc(0.5 * 3.5px);
  text-transform: uppercase;
  color: #313131;
  cursor: pointer;
  box-sizing: border-box;
}

input.submit:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
  opacity: 0.5;
}
