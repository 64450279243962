@import "../../App.css";

.text-wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  padding: 20px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
}
@media only screen and (max-width: 1000px) {
  .text-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    padding: 20px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    width: 90%;
    padding-bottom: 60px;
  }
}
.profile-image {
  aspect-ratio: 1/1;
  min-width: 100px;
  max-width: 385.66px;
  width: 50%;
  min-height: 100px;
  max-height: 385.66px;
  object-fit: cover;
  height: "auto";
}
@media only screen and (max-width: 1000px) {
  .profile-image {
    aspect-ratio: 1/1;
    min-width: 100px;
    max-width: 385.66px;
    min-height: 100px;
    max-height: 385.66px;
    object-fit: cover;
    height: "auto";
    aspect-ratio: 1/1;
    width: 90%;
  }
}

.rps-logo {
  padding-top: 20px;
  width: 150px;
  align-self: bottom;
}

.flex-grow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
}

.hello-title {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  padding-bottom: 14px;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: auto;

  align-items: center;
  justify-content: space-around;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  background: rgb(49, 49, 49);
  background: linear-gradient(
    180deg,
    rgba(49, 49, 49, 0) 0%,
    rgba(0, 0, 0, 1) 68%
  );
}

@media only screen and (max-width: 1000px) {
  .footer {
    display: flex;
    margin-top: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    background: rgb(49, 49, 49);
    background: linear-gradient(
      180deg,
      rgba(49, 49, 49, 0) 0%,
      rgba(0, 0, 0, 1) 68%
    );
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.adobe {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.badge-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}

.text-image-wrapper {
  display: flex;
  flex-direction: row-reverse;
  max-width: 800px;
  min-height: 400px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

@media only screen and (max-width: 1000px) {
  .text-image-wrapper {
    display: flex;
    max-width: 800px;
    min-height: 400px;
    justify-content: center;
    align-items: center;
    height: fit-content;

    flex-direction: column;
  }
}
