.galleryContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

iframe {
  width: 104%;
  height: 90%;
  border: none;
  overflow: hidden;
  margin-top: 60px;
}

.iframe-container {
  overflow: hidden;
  width: 95%;
  height: 100%;
  margin-top: 110px;
}

iframe::-webkit-scrollbar {
  display: none;
}
