@import "../../App.css";

form {
  width: 100%;
  height: fit-content;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
}

@media only screen and (min-width: 1001px) {
  .content-wrapper.contact {
    min-width: 800px;
  }
}
@media only screen and (max-width: 1000px) {
  .content-wrapper.contact {
    position: static;
    min-width: auto;
    width: 95%;
  }
}
