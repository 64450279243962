@import "../../App.css";

.homeContainer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding: 0;
}

.MuiIcon-colorPrimary {
  color: white !important;
  filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 0.8));
}

.videoBackground {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.videoBackground.hidden {
  background-image: url("../../assets/video/ang-website-still.png")
    rgba(0, 0, 0, 0);
}

.videoBackground.visible {
  background-image: url("../../assets/video/ang-website-still.png");
}

.imageBackground {
  background-image: url("../../assets/video/ang-website-still.png");
}

.videoIconStyle {
  color: white;
  filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 0.8));
}

.videoIconStyle:hover {
  color: lightgrey;
  filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 0.8));
}
