.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image.compressed {
  filter: blur(2px);
}

.image.compressed.hidden,
.image.hidden {
  display: none;
}

.image.compressed.show,
.image.show {
  display: block;
}
